import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostBinding,
  SimpleChanges,
  HostListener
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SessionService } from '../../../../../../angular-services-v7/session.service';
import { TranslationService } from '../../../../../../angular-localization-v7/services/translation.service';
import { Broadcaster } from '../../../../../../events-v7/projects/events-v7/src/public_api';

import { Language } from './model/language';
import { TokenResponse } from './model/token-response';
import { CookiebotService } from './../../../../../../cmx-cookiebot-v7/services/cmx-cookiebot.service';

@Component({
    selector: 'cmx-login',
    templateUrl: './cmx-login.component.html',
    styleUrls: ['./../../../../../../../../scss/cmx-login/v7/cmx-login.component.scss']
})
export class CmxLoginComponent implements OnInit {
    @Input()
    public title: string = '';
    @Input()
    public width: string = '';
    @Input()
    public forgotPasswordUrl: string;
    @Input()
    public appName: string;
    @Input()
    public validateTemporaryPassword: boolean = false;
    @Input()
    public urlRoute = '';
    @Input()
    public showChrome: boolean = true;
    @Input()
    public logoUrl: string = null;
    @Input()
    public designVersion = 'v1';
    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._rtl = $value;
            this.dirAttr = $value ? 'rtl' : 'ltr';
        }
    }

    public loginForm: FormGroup;
    public loginFailed = false;
    public submitting = false;
    public currentLang: Language;
    public errorMsg: string;
    public logoStyles: any;
    public showPassword: boolean = false;
    public companyName: string;
    public nextLoginAllowed: string;

    @Output()
    public loggedIn = new EventEmitter();

    @Output()
    public loginAttemptFail = new EventEmitter<any>();

    @HostBinding('attr.dir')
    public dirAttr: string = 'auto';

    private generateCookie = true;
    private _rtl: boolean = false;
    private grantType = 'password';
    private scope = 'security';
    private userInformation = 'false';
    private include = 'userinfo,profile,applications,roles,customers,oldVersion';
    private routeAfterLogin: 'dashboard' | 'resetPassword';
    private euHost = window['HOST_EUROPE'];
    private ameHost = window['HOST_AMERICA'];
    private euRegion = ['GB', 'UK'];
    private onServiceReadyCookiebot = false;

    constructor(
        public translationService: TranslationService,
        private fb: FormBuilder,
        private sessionService: SessionService,
        private eventBroadcaster: Broadcaster,
        private router: Router,
        private cookiebotService: CookiebotService,
    ) {

        this.getLogo();

        this.loginForm = this.fb.group({
            username: [null, Validators.required],
            password: [null, Validators.required]
        });

        this.loginForm.valueChanges.subscribe(() => {
            this.loginFailed = false;
        });
    }

    @HostListener('document:keyup.enter') public checkSubmit(): void {
        this.authenticate();
    }

    public authenticate(): void {
      if (!this.loginForm.valid) {
        return;
      }

      const username: string = this.loginForm.get('username').value;
      const password: string = this.loginForm.get('password').value;
      let consent = null;
      if(this.onServiceReadyCookiebot) {
        consent = this.cookiebotService.getConsent();
      }
      else {
        if(this.cookiebotService.getLoadScript()) {
          consent = this.cookiebotService.getErrorScriptCookiebot(username);
        }
      }

      this.nextLoginAllowed = '';
      this.submitting = true;
      this.sessionService
        .login(username, password,
          this.generateCookie,
          this.appName,
          this.validateTemporaryPassword,
          this.grantType,
          this.scope,
          this.userInformation,
          this.include,
          null,
          consent)
        .then((tokenResponse: TokenResponse) => {
            this.loggedIn.emit();
            this.loginFailed = false;
            this.submitting = false;
            // Redirection between regions (America / Europe) depending on user region.z
            const userRegion =
                tokenResponse ? tokenResponse.oauth2.region : this.sessionService.userRegion;
            const regionHost: string =
                (this.euRegion.indexOf(userRegion) > -1) ?
                    this.euHost : this.ameHost;

            const hasTemporaryPassword = tokenResponse &&
                tokenResponse.profile && tokenResponse.profile.hasTemporaryPassword;
            const canChangePassword = tokenResponse &&
                tokenResponse.profile && tokenResponse.profile.canChangePassword;

            if (hasTemporaryPassword && canChangePassword) {
                this.routeAfterLogin = 'resetPassword';
                const tempPasswordHandler = {
                    access_token: tokenResponse.oauth2.access_token,
                    jwt: tokenResponse.jwt,
                    username,
                    firstName: tokenResponse.profile.firstName,
                    lastName: tokenResponse.profile.lastName,
                    country: tokenResponse.country
                };
                this.sessionService
                    .createCookie('cmxTmpSession', JSON.stringify(tempPasswordHandler));
            } else {
                this.sessionService.clearCookie('cmxTmpSession');
                this.routeAfterLogin = 'dashboard';
            }

            const params = new URLSearchParams();
            if (this.urlRoute) {
                params.append('returnUrl', this.urlRoute);
            }

            // If user is already on the right region, does normal routing
            const currentHost = window.location.host;
            if (regionHost !== currentHost) {
                const language = this.translationService.selectedLanguage;
                this.sessionService.createCookie('cmxgo_language', language.languageISO, 60);
                sessionStorage.clear();
                const baseUrl =
                    `${window.location.protocol}//${regionHost}/${this.routeAfterLogin}`;
                window.location.href =
                    `${baseUrl}?returnUrl=${encodeURIComponent(this.urlRoute)}`;
            } else {
                const destionationUrl = this.routeAfterLogin + '?' + params.toString();
                this.router.navigateByUrl(destionationUrl);
            }
        })
        .catch((err) => {
            this.nextLoginAllowed = '';
            this.errorMsg = 'views.login.loginErrorSystem';
            if (err.status === 418) {
                // CEMEX GO is currently unavailable in your country due to maintenance. Please try again later.
                this.errorMsg = 'views.login.403Error';
            }
            if (err.status === 401) {
                // The user or password you entered are incorrect. Please try again.
                this.errorMsg = 'views.login.error';
            }
            if (err.status === 429) {
                // Login Attempts exceeded, the next allowed login is ###
                const objson = JSON.parse(err._body);
                const theDate = new Date(Date.parse(objson.moreInformation + ' UTC'));
                this.errorMsg = 'views.login.429Error';
                this.nextLoginAllowed = theDate.toLocaleString();
            }
            //  User requested for edit password, redirect to login's temporary password form
            if (err.status === 423) {
                const destionationUrl = `login?userAccount=${username}&process=MPR`;
                this.router.navigateByUrl(destionationUrl);
            }
            this.loginFailed = true;
            this.submitting = false;
            // Event with complete message
            this.loginAttemptFail.emit(err);
        });
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            const usrInput = this.loginForm.get('username');
            const usrValue = usrInput.value || '';
            if (usrValue.length > 0) {
                this.loginForm.controls['password'].setErrors(null);
            }
        }, 1000);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes['logoUrl']) {
            this.getLogo();
        }
    }

    /**
     * Go to forgot password, default to /forgotPassword unless a custom absolute url is defined
     */
    public goToForgotPassword(): void {
        if (!this.forgotPasswordUrl) {
            this.router.navigate(['/forgotPassword']);
        } else {
            location.href = this.forgotPasswordUrl;
        }
    }

    public goToSingleOrderSignIn(): void {
      window.location.href = '/track'
    }

    public ngOnInit(): void {
        this.sessionService.logout();
        this.eventBroadcaster.on<string>(Broadcaster.DCM_APP_LOGOUT)
            .subscribe((response) => {
                this.sessionService.clearCookie('up_session');
            });

        this.translationService.languageLoaded.subscribe((data) => this.getLogo());

        this.cookiebotService.onServiceReady$.subscribe((response) => {
          this.onServiceReadyCookiebot = response;
        }, error => {
          console.error('Error on cookiebotService: ', error);
          this.onServiceReadyCookiebot = false;
        });
    }

    private getLogo(): void {
        let language = '';
        language = localStorage.getItem('language') ||
            sessionStorage.getItem('language') || 'en_US';

        const country = language.substr(3, 5);

        if (!this.logoUrl) {
            if (country === 'IL') {
                this.logoStyles = {
                    backgroundImage: 'url(https://www.cemexgo.com/cdn/logos/svg/readymix.co.il.white.svg)'
                };
                this.companyName = 'Readymix';
            } else {
                this.logoStyles = {
                    backgroundImage: 'url(https://www.cemexgo.com/cdn/logos/svg/cemex_mono_white.svg)'
                };
                this.companyName = 'CEMEX';
            }
        } else {
            this.logoStyles = { backgroundImage: 'url(' + this.logoUrl + ')' };
        }
    }

    public disabledSubmit(): boolean {
      if(this.onServiceReadyCookiebot) {
        const consent = this.cookiebotService.getConsent();
        return (!this.loginForm.valid || this.submitting || consent === null);
      }
      else {
        return (!this.loginForm.valid || this.submitting);
      }
    }
}
