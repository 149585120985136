import { Component, ElementRef, ViewChild, AfterViewChecked, HostListener } from '@angular/core';
import { StoreService } from './services/store.service';
import { SessionService } from '../../../../../../angular-services-v7/session.service';
import { LegalEntity } from '../../../../../../angular-services-v7/legalEntity.service';
import { ScriptService } from './services/script.service';
import { TranslationService } from '../../../../../../angular-localization-v7/services/translation.service';
import { ILegalEntity } from '../../../../../../angular-types-v2/interfaces/user-data/ilegal-entity.dto';
import { ScriptStore } from './store/script.store';
import { ChangeDetectorRef, AfterContentChecked} from '@angular/core';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    chatWidget: any;
    $aivo: any;
    $: any;
    API_HOST: any;
  }
}

export interface IAuth {
  access_token?: string | null;
  jwt?: string | null;
  customer_id?: number | null;
  customer_code?: string | null;
  country_code?: string | null;
  user_id?: string | null;
  user_timezone?: string | null;
}

enum messageChatInit {
  en = 'Start conversation',
  es = 'Iniciar conversación'
}

enum languagesAvailable {
  English = 'en',
  Spanish = 'es'
}

enum countriesAvailable {
  PH,
  MX,
  US,
  CO,
  ES,
  GB,
  GT,
  PA,
  PE,
  DO,
  PR,
  NI
}

enum countriesAvailableScript {
  PH,
  MX,
  US,
  CO,
  ES,
  GB,
  GT,
  PA,
  PE,
  DO,
  PR,
  NI
}

export interface ILanguageSelect {
  languageName: string;
  languageISO: string;
  isSelected: boolean;
}

@Component({
  selector: 'cmx-chatbot',
  styleUrls: ['chatbot.component.scss'],
  templateUrl: 'chatbot.component.html',
})

export class ChatbotComponent implements AfterViewChecked {
  @ViewChild('botWindow') public botWindowElement: ElementRef;
  @ViewChild('cbdock') public dock: ElementRef;
  @ViewChild('cbfloat') public floatContainer: ElementRef;
  private globalCustomerCode: string | any;
  public widgetHasInit: boolean;
  public sessionStorage = sessionStorage;
  private globalLan = '';
  private globalCountry = '';
  private loadAivo = false;
  private IsLogged = false;
  public chatIsOpen = false;
  public showChatButton = false;
  public showDockButton = true;
  public firstTimeSetting = true;
  public resetLanguage = false;
  public en_script = false;
  public es_script = false;
  private mobileBreakpoint = 576;
  isMobile = false;
  private logMessage(msg) {
    // console.log('AIVO LOG', msg);
  }

  private getAlreadyLoadded() {
    return sessionStorage.getItem('aivo.isloaded') === 'true';
  }

  private setAlreadyLoadded(state) {
    sessionStorage.setItem('aivo.isloaded', state);
  }

  private setMobileLoaded() {
    if (this.isMobile) {
      // console.log('this.isMobile = true');
      this.ClickOnCloseButton();
    } else {
      this.ClickOnDockButton();
    }
  }

  constructor(
    private store: StoreService,
    private sessionService: SessionService,
    private translationService: TranslationService,
    private scriptService: ScriptService,
    private cdref: ChangeDetectorRef
  ) {
    this.showChatButton = true;
    this.widgetHasInit = false;
    this.detectMobile();
    const IsNotProd = window.API_HOST.includes('pp') || window.API_HOST.includes('qa') || window.API_HOST.includes('dev');
    if (IsNotProd) {
      console.log('Olivia chatbot started: 1.0.10');
    }
    this.sessionService.currentLegalEntity.subscribe(
      (legalEntity: ILegalEntity) => {
        this.logMessage('Change in Legal Entity');
        if (legalEntity !== null) {
          this.logMessage('Legal Entity SET TO: ' + legalEntity.legalEntityDesc);
          if (window.$aivo !== null && window.$aivo !== undefined
            && window.$aivo.chat !== undefined) {
              window.$aivo.user.set('customField1', String(legalEntity.legalEntityDesc));
            }
          this.logMessage('Call this.onDetectChangesSessionStore');
          this._onDetectChangesSessionStore();
          if (!this.globalCustomerCode) {
            this.logMessage('Inside condition !this.globalCustomerCode');
            this.globalCustomerCode = this.store.customerCode;
            this.logMessage('Set this.globalCustomerCode to: ' + this.store.customerCode);
          }
        }
      });

    this.translationService.getSelectedLanguage().subscribe(
      (language: any) => {
        this.logMessage('Call this.getCurrentCountry()');
        const country = this.getCurrentCountry();
        const languageByCountry = this.translationService.getLanguageByCountryCode(country).languageISO.substring(0, 2);
        this.logMessage('this.translationService country value: ' + country);
        this.logMessage('this.translationService languageByCountry value: ' + languageByCountry);
        if (country in countriesAvailable) {
          this.logMessage('Inside ' + country + 'in countriesAvailable to minimize or close AIVO chat');
          try {
            this.logMessage('Inside try catch');
            if (this.sessionStorage.hasOwnProperty('jwt') && window.$aivo.user.get() !== null && window.$aivo.user.get() !== undefined
              && window.$aivo.user.get().jwt === '') {
              this.logMessage('CLOSE AIVO 1');
              window.$aivo.chat.close();
            } else {
              this.logMessage('MINIMIZE AIVO');
              window.$aivo.chat.minimize();
            }
          } catch (err) {
            this.logMessage('Catch error: ' + err);
          }
          this.logMessage('Call this._onDetectChangesLanguage');
          this._onDetectChangesLanguage(languageByCountry);
        } else {
          this.logMessage('Inside ELSE condition from' + country + 'in countriesAvailable to close AIVO chat');
          this.showChatButton = false;
          this.logMessage('this.showChatButton = false');
          try {
            this.logMessage('CLOSE AIVO 2');
            window.$aivo.chat.close();
          } catch (err) {
          }
        }
      });
  }

  public ngAfterViewChecked(): void {
    if (!this.widgetHasInit) {
      this.initChatbot();
    }
    this.cdref.detectChanges();
  }

  public initChatbot(): void {
    this.logMessage('initChatbot');
    try {
      this.fireEvent('MountEventv2', window);
      this.logMessage('Call this.getCurrentCountry()');
      const country = this.getCurrentCountry();
      let languageByCountry = this.translationService.getLanguageByCountryCode(country).languageISO.substring(0, 2);
      this.logMessage('initChatbot country: ' + country);
      if (country in countriesAvailable) {
        this.logMessage('Inside ' + country + 'in countriesAvailable to check if this.widgetHasInit');
        if (this.widgetHasInit === false) {
          if (languageByCountry === languagesAvailable.Spanish) {
            this.en_script = false;
            this.es_script = true;
          } else {
            this.es_script = false;
            this.en_script = true;
          }
          const IsNotProd = window.API_HOST.includes('pp') || window.API_HOST.includes('qa') || window.API_HOST.includes('dev');
          let script_name = '';
          const scriptToLoad = this.translationService.getLanguageByCountryCode(country).languageISO;
          if (IsNotProd) {
            script_name = scriptToLoad.concat('_test');
            languageByCountry = script_name;
          } else {
            script_name = scriptToLoad;
            languageByCountry = script_name;
          }
          if (sessionStorage.hasOwnProperty('jwt')) {
            script_name = languageByCountry.concat('_private');
            this.logMessage('PRIVATE script to load');
          } else {
            this.logMessage('PUBLIC script to load');
            script_name = languageByCountry.concat('_public');
          }
          languageByCountry = script_name;
          this.logMessage('this.widgetHasInit === false');
          this.logMessage('Call this.changeScript(' + languageByCountry + ')');
          this.logMessage('Call this.changeScript(' + script_name + ')');
          this.changeScript(languageByCountry);
          this.widgetHasInit = true;
          this.logMessage('this.widgetHasInit = true');
        }
      }
    } catch (err) {
    }
  }

  public getInitMessage() {
    this.logMessage('Call this.getCurrentCountry()');
    const country = this.getCurrentCountry();
    const languageByCountry = this.translationService.getLanguageByCountryCode(country).languageISO.substring(0, 2);
    this.globalLan = languageByCountry;
    return messageChatInit[languageByCountry];
  }

  public OpenChat() {
    this.logMessage('OpenChat');
    let IsClosed2 = false;
    const country = this.getCurrentCountry();
    try {
      if (this.loadAivo) {
        this.logMessage('1. this.loadAivo');
        this.logMessage('COUNTRY before condition: ' + country);
        if (window.$aivo !== null && window.$aivo !== undefined
          && window.$aivo.chat !== undefined && window.$aivo.user.get().countryName === country) {
          this.logMessage('INSIDE Condition country === countryName AIVO');
          if ((window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth) <= 700) {
            document.getElementById('AgentAppContainer').style.display = 'block';
          }
          if (window.$aivo !== null && window.$aivo !== undefined
            && window.$aivo.chat !== undefined) {
            window.$aivo.chat.open();
            const IsClosed = window.$aivo.user.get().IsClosed;
            IsClosed2 = IsClosed;
            this.logMessage('IsClosed value: ' + IsClosed);
            if (IsClosed) {
              this.logMessage('Inside condition IsClosed === true');
              this.firstTimeSetting = true;
              window.$aivo.user.set('IsClosed', '');
            }
          }
        } else {
          this.logMessage('Inside ELSE condition 1. this.loadAivo to do RETURN');
          return;
        }
        if (this.sessionStorage.getItem('jwt') !== null) {
          this.IsLogged = true;
        }
        if (this.firstTimeSetting) {
          this.logMessage('Inside condition this.firstTimeSetting');
          if (!this.IsLogged && !this.getAlreadyLoadded()) {
            this.firstTimeSetting = false;
            this.resetLanguage = false;
          } else if (this.IsLogged && !this.getAlreadyLoadded()) {
            this.firstTimeSetting = false;
            this.resetLanguage = false;
            this.setAlreadyLoadded(true);
          } else if (IsClosed2) {
            this.logMessage('Inside condition IsClosed2');
            this.logMessage('Call this.getInitMessage()');
            this.firstTimeSetting = false;
            this.resetLanguage = false;
            IsClosed2 = false;
          } else {
            this.logMessage('else');
          }
        } else if (this.resetLanguage) {
          this.resetLanguage = false;
        }
      }

    } catch (err) {
      this.logMessage('error1: ' + err);
      this.firstTimeSetting = true;
    }

  }

  public ClickOnCloseButton() {
    document.getElementById('cbdock').classList.add('active');
    document.getElementById('cbfloat').classList.add('hidden');
    document.getElementById('cbpopup').classList.remove('hidden');
    setTimeout(() => {
      document.getElementById('cbpopup').classList.add('hidden');
    }, 2000);
  }

  public ClickOnDockButton() {
    document.getElementById('cbdock').classList.remove('active');
    document.getElementById('cbfloat').classList.remove('hidden');
    document.getElementById('cbpopup').classList.add('hidden');
  }

  public getCurrentCountry() {
    let positionOfUnderscore: number;
    if (this.sessionStorage.getItem('country') !== null) {
      return this.sessionStorage.getItem('country');
    }
    let currentCountry: string;
    if (this.sessionStorage.getItem('language') !== null) {
      positionOfUnderscore = this.sessionStorage.getItem('language').indexOf('_') + 1;
      currentCountry = this.sessionStorage.getItem('language').substring(positionOfUnderscore);
    } else if (localStorage.getItem('language') !== null) {
      positionOfUnderscore = localStorage.getItem('language').indexOf('_') + 1;
      currentCountry = localStorage.getItem('language').substring(positionOfUnderscore);
    } else if (window['LANGUAGE'] !== null) {
      positionOfUnderscore = window['LANGUAGE'].indexOf('_') + 1;
      currentCountry = window['LANGUAGE'].substring(positionOfUnderscore);
    }
    return currentCountry;
  }

  public getCurrentLanguage() {
    let currentLanguage: string;
    if (this.sessionStorage.getItem('language').substring(0, 2) !== null ||
    localStorage.getItem('language').substring(0, 2) !== null ||
    window['LANGUAGE'].substring(0, 2) !== null) {
      currentLanguage = this.sessionStorage.getItem('language').substring(0, 2) ||
      localStorage.getItem('language').substring(0, 2) ||
      window['LANGUAGE'].substring(0, 2);
    }
    return currentLanguage;
  }

  public changeScript(script: string): void {
    this.logMessage('changeScript(' + script + ')');
    this.logMessage('Call this.getCurrentCountry()');
    const country = this.getCurrentCountry();
    const self = this;
    let name;
    let email;
    let firstname;
    let lastname;
    let rfc = '';
    let jwt;
    let phone;
    let public_chat = false;
    if (sessionStorage.hasOwnProperty('jwt')) {
      this.logMessage('sessionStorage.hasOwnProperty(jwt)');
      // const userLegalEntity: string | null = sessionStorage.getItem('user_legal_entity');
      name = this.sessionService.userProfile.fullName;
      email = this.sessionService.userProfile.userAccount;
      firstname = this.sessionService.userProfile.firstName;
      lastname = this.sessionService.userProfile.lastName;
      jwt = this.sessionStorage.getItem('jwt');
      // rfc = JSON.parse(userLegalEntity).legalEntityDesc as string;
      phone = this.sessionService.userProfile.phoneNumber;
    }
    let IsLogged = false;
    if (country in countriesAvailable) {
      this.logMessage('Inside condition ' + country + ' in countriesAvailable before calling to loadScript(' + script + ')');
      if (sessionStorage.hasOwnProperty('jwt') && self.getAlreadyLoadded() === true) {
        this.logMessage('Inside condition that evaluates if aivo exists and jwt is true to dont call loadScript');
      }
      this.logMessage('Calls loadScript');
      if (sessionStorage.getItem('user_legal_entity') !== null) {
        this.logMessage('sessionStorage.getItem(user_legal_entity)!==null');
        rfc = JSON.parse(String(sessionStorage.getItem('user_legal_entity'))).legalEntityDesc;
      } else {
        rfc = '';
      }
      this.scriptService.loadScript(script).then(data => {
        this.logMessage('Inside this.scriptService.loadScript(' + script + ')');
        try {
          window.$aivo.ready(function () {
            self.logMessage('Inside window.$aivo.ready(function())');
            if (sessionStorage.hasOwnProperty('jwt')) {
              public_chat = false;
              self.logMessage('PRIVATE CHAT');
            } else {
              self.logMessage('PUBLIC CHAT');
              public_chat = true;
            }

            if (!public_chat && self.getAlreadyLoadded() === false) {
              self.logMessage('NEW SESSION - PRIVATE');
              window.$aivo.user.new();
              window.$aivo.user.set({
                'countryName': country,
                'jwt': 'true',
                'fullName': name,
                'userAccount': email,
                'name': name,
                'email': email,
                'IsClosed': '',
                'firstName': firstname,
                'lastName': lastname,
                'customField1': rfc,
                'customField1Label': 'Company Name',
                'phoneNumber': phone
              });
              /* window.$aivo.user.set('countryName', country);
              window.$aivo.user.set('jwt', 'true');
              window.$aivo.user.set('fullName', name);
              window.$aivo.user.set('userAccount', email);
              window.$aivo.user.set('name', name);
              window.$aivo.user.set('email', email);
              window.$aivo.user.set('IsClosed', '');
              window.$aivo.user.set('firstName', firstname);
              window.$aivo.user.set('lastName', lastname);
              window.$aivo.user.set('customField1', rfc);
              window.$aivo.user.set('customField1Label', 'Company Name');
              window.$aivo.user.set('phoneNumber', phone); */
              public_chat = false;
            }
            if (!public_chat && self.getAlreadyLoadded() === true) {
              self.logMessage('SAME SESSION - PRIVATE');
              window.$aivo.user.set({
                'countryName': country,
                'jwt': 'true',
                'fullName': name,
                'userAccount': email,
                'name': name,
                'email': email,
                'IsClosed': '',
                'firstName': firstname,
                'lastName': lastname,
                'customField1': rfc,
                'customField1Label': 'Company Name',
                'phoneNumber': phone
              });
              /* window.$aivo.user.set('countryName', country);
              window.$aivo.user.set('jwt', 'true');
              window.$aivo.user.set('fullName', name);
              window.$aivo.user.set('userAccount', email);
              window.$aivo.user.set('IsClosed', '');
              window.$aivo.user.set('name', name);
              window.$aivo.user.set('email', email);
              window.$aivo.user.set('firstName', firstname);
              window.$aivo.user.set('lastName', lastname);
              window.$aivo.user.set('customField1', rfc);
              window.$aivo.user.set('customField1Label', 'Company Name');
              window.$aivo.user.set('phoneNumber', phone); */
              public_chat = false;
            }
            if (public_chat) {
              self.logMessage('NEW SESSION - PUBLIC');
              window.$aivo.user.new();
              window.$aivo.user.set({
                'countryName': country,
                'jwt': '',
                'fullName': '',
                'userAccount': '',
                'name': '',
                'email': '',
                'IsClosed': '',
                'firstName': '',
                'lastName': '',
                'customField1': '',
                'customField1Label': 'Company Name',
                'phoneNumber': ''
              });
              /* window.$aivo.user.set('countryName', country);
              window.$aivo.user.set('jwt', '');
              window.$aivo.user.set('fullName', '');
              window.$aivo.user.set('userAccount', '');
              window.$aivo.user.set('IsClosed', '');
              window.$aivo.user.set('name', '');
              window.$aivo.user.set('email', '');
              window.$aivo.user.set('firstName', '');
              window.$aivo.user.set('lastName', '');
              window.$aivo.user.set('customField1', '');
              window.$aivo.user.set('customField1Label', '');
              window.$aivo.user.set('phoneNumber', ''); */
              public_chat = false;
              IsLogged = false;
              self.setAlreadyLoadded(false);
              self.logMessage('3. this.setAlreadyLoadded ' + self.getAlreadyLoadded());
            }

            window.$aivo.chat.onCloseWindow(function () {
              self.logMessage('window.$aivo.chat.onCloseWindow');
              window.$aivo.user.set('IsClosed', 'true');
              document.getElementById('cbdock').classList.add('active');
              document.getElementById('cbfloat').classList.add('hidden');
              document.getElementById('cbpopup').classList.remove('hidden');
              setTimeout(() => {
                document.getElementById('cbpopup').classList.add('hidden');
              }, 2000);
            });

            window.$aivo.chat.onMinimizeWindow(function () {
              document.getElementById('cbdock').classList.remove('active');
              document.getElementById('cbfloat').classList.remove('hidden');
              document.getElementById('cbpopup').classList.add('hidden');
              if ((window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth) <= 700) {
                document.getElementById('AgentAppContainer').style.display = 'none';
              }
            });
            self.setMobileLoaded();
          });
        } catch (err) {
          self.logMessage('ERROR LOAD SCRIPT: ' + err);
        }

      }).catch();
      self.IsLogged = IsLogged;
      self.logMessage('1. this.setAlreadyLoadded ' + self.getAlreadyLoadded());
      self.loadAivo = true;
      self.logMessage('4. this.loadAivo ' + self.loadAivo);

    }
  }

  public deleteScript(script: string): void {
    this.logMessage('deleteScript( ' + script + ' )');
    if (document.getElementById('AgentAppContainer') !== null) {
      this.logMessage('document.getElementById(AgentAppContainer)!==null');
      delete window.$aivo;
      const element = document.getElementById('AgentAppContainer');
      element.parentNode.removeChild(element);
      this.scriptService.unloadScript(script);
      this.loadAivo = false;
      this.logMessage('5. this.loadAivo ' + this.loadAivo);
    }
  }

  public _onDetectChangesSessionStore(): void {
    this.logMessage('_onDetectChangesSessionStore()');
    const changedCustomerCode: any = this.globalCustomerCode !== this.store.customerCode;
    if (changedCustomerCode) {
      this.logMessage('changedCustomerCode');
      const justLoggedIn: any = !this.globalCustomerCode;
      if (justLoggedIn) {
        this.logMessage('justLoggedIn');
        this._withCredentials();
        this.logMessage('Call this.getCurrentCountry()');
        const country = this.getCurrentCountry();
        const languageByCountry = this.translationService.getLanguageByCountryCode(country).languageISO.substring(0, 2);
        if (country in countriesAvailable) {
          this.logMessage('Call this._onDetectChangesLanguage(' + languageByCountry + ')');
          this._onDetectChangesLanguage(languageByCountry);
        }
      }
      this.globalCustomerCode = changedCustomerCode;
      this.logMessage('this.globalCustomerCode value: ' + changedCustomerCode);
    }
  }

  public _onDetectChangesLanguage(lang: string): void {
    this.logMessage('_onDetectChangesLanguage( ' + lang + ' )');
    this.showChatButton = false;
    this.logMessage('this.showChatButton value:' + this.showChatButton);
    this.logMessage('Call this.getCurrentCountry()');
    const country = this.getCurrentCountry();
    this.globalCountry = country;
    let languageByCountry = this.translationService.getLanguageByCountryCode(country).languageISO.substring(0, 2);
    let languageByDefault = languageByCountry;
    this.logMessage('languageByDefault ' + languageByDefault);
    if (languageByCountry === languagesAvailable.Spanish) {
      this.logMessage('languageByCountry === languagesAvailable.Spanish ' + languageByCountry);
      languageByDefault = languagesAvailable.English;
      this.en_script = false;
      this.es_script = true;
    } else {
      this.logMessage('languageByCountry !== languagesAvailable.Spanish ' + languageByCountry);
      languageByDefault = languagesAvailable.Spanish;
      this.es_script = false;
      this.en_script = true;
    }
    const IsNotProd = window.API_HOST.includes('pp') || window.API_HOST.includes('qa') || window.API_HOST.includes('dev');
    let script_name = '';
    const scriptToLoad = this.translationService.getLanguageByCountryCode(country).languageISO;
    languageByCountry = scriptToLoad;
    ScriptStore.forEach((script: any) => {
      if (this.scriptService.getScriptLoaded(script.name)) {
         languageByDefault = script.name;
      }
    });
    if (IsNotProd) {
      script_name = languageByCountry.concat('_test');
      languageByCountry = script_name;
    } else {
      script_name = scriptToLoad;
      languageByCountry = script_name;
    }
    if (sessionStorage.hasOwnProperty('jwt')) {
      script_name = languageByCountry.concat('_private');
      this.logMessage('PRIVATE script to load');
    } else {
      this.logMessage('PUBLIC script to load');
      script_name = languageByCountry.concat('_public');
    }
    this.logMessage('script to load: ' + script_name);
    languageByCountry = script_name;
    this.logMessage('languageByCountry to load: ' + languageByCountry);
    this.logMessage('languageByDefault to unload: ' + languageByDefault);
    if (country in countriesAvailable) {
      this.showChatButton = true;
      this.logMessage('Inside ' + country + ' in countriesAvailable to check script to unload and load');
      if (this.sessionStorage.getItem('jwt') !== null && this.getAlreadyLoadded() === false) {
        this.logMessage('PRIVATE FOR FIRST TIME');
        if (!this.scriptService.getScriptLoaded(languageByCountry)) { // cargado un script diferente
          this.logMessage('Inside condition !this.scriptService.getScriptLoaded( ' + languageByCountry + ' )');
          this.logMessage('1. this.scriptService.getScriptLoaded( ' + languageByDefault + ' )');
          this.logMessage('Call this.deleteScript(' + languageByDefault + ')');
          this.deleteScript(languageByDefault);
          this.logMessage('Call this.changeScript(' + languageByCountry + ')');
          this.changeScript(languageByCountry);
          this.globalLan = languageByCountry;

          this.logMessage('7.this.showChatButton value:' + this.showChatButton);
        } else if (this.scriptService.getScriptLoaded(languageByCountry)) { // cargado mismo script
          this.logMessage('Inside condition this.scriptService.getScriptLoaded( ' + languageByCountry + ' )');
          this.logMessage('4. this.scriptService.getScriptLoaded( ' + languageByCountry + ' )');
          this.logMessage('Call this.deleteScript(' + languageByCountry + ')');
          this.deleteScript(languageByCountry);
          this.logMessage('Call this.changeScript(' + languageByCountry + ')');
          this.changeScript(languageByCountry);
          this.globalLan = languageByCountry;
          this.logMessage('6. this.showChatButton value:' + this.showChatButton);

        } else if (!this.scriptService.getScriptLoaded(languageByCountry) && !this.scriptService.getScriptLoaded(languageByDefault)) {
          this.changeScript(languageByCountry);
          this.globalLan = languageByCountry;
          this.logMessage('5. this.showChatButton value:' + this.showChatButton);

        }
      } else if (this.sessionStorage.getItem('jwt') === null) {
        this.logMessage('PUBLIC CONDITION');
        if (!this.scriptService.getScriptLoaded(languageByCountry)) {// cargado script diferente
          this.logMessage('Inside condition !this.scriptService.getScriptLoaded(languageByCountry)');
          this.logMessage('5. this.scriptService.getScriptLoaded( ' + languageByDefault + ' )');
          this.logMessage('Call this.deleteScript(' + languageByDefault + ')');
          this.deleteScript(languageByDefault);
          this.logMessage('Call this.changeScript(' + languageByCountry + ')');
          this.changeScript(languageByCountry);
          this.globalLan = languageByCountry;
          this.logMessage('4.this.showChatButton value:' + this.showChatButton);


        } else if (this.scriptService.getScriptLoaded(languageByCountry)) {// cargado mismo script
          this.logMessage('Inside condition this.scriptService.getScriptLoaded(languageByCountry)');
          this.logMessage('8. this.scriptService.getScriptLoaded( ' + languageByCountry + ' )');
          this.logMessage('Call this.deleteScript(' + languageByCountry + ')');
          this.deleteScript(languageByCountry);
          this.logMessage('Call this.changeScript(' + languageByCountry + ')');
          this.changeScript(languageByCountry);
          this.globalLan = languageByCountry;
          this.logMessage('3. this.showChatButton value:' + this.showChatButton);

        }
      } else if (this.getAlreadyLoadded() === true && this.sessionStorage.getItem('jwt') !== null) {
        this.logMessage('PRIVATE MORE THAN ONCE');
      }

    } else {
      if (this.scriptService.getScriptLoaded(languageByCountry) || this.scriptService.getScriptLoaded(languageByDefault)) {
        this.logMessage('this.scriptService.getScriptLoaded(languageByCountry) || this.scriptService.getScriptLoaded(languageByDefault)');
        if (this.scriptService.getScriptLoaded(languageByCountry)) {
          languageByDefault = languageByCountry;
        }
        this.showChatButton = false;
        this.logMessage('Call this.deleteScript(' + languageByDefault + ')');
        this.deleteScript(languageByDefault);
        this.logMessage('2. this.showChatButton value:' + this.showChatButton);
      } else {
        this.logMessage('else 2');
      }
    }
    this.resetLanguage = true;
  }


  private _withCredentials(): void {
    this.logMessage('_withCredentials()');
    if (this.sessionStorage.getItem('jwt') !== null) {
      this.logMessage('Inside condition this.sessionStorage.getItem(jwt) !== null');
      const name = this.sessionService.userProfile.fullName;
      const email = this.sessionService.userProfile.userAccount;
      this.IsLogged = true;
    }
  }
  @HostListener('window:resize', [])
  onResize(): void {
      this.detectMobile();
      this.setMobileLoaded();
  }

  private detectMobile(): void {
      this.isMobile = window.innerWidth <= this.mobileBreakpoint;
  }
  private fireEvent(name: string, target: any): void {
    target.dispatchEvent(new Event(name, { bubbles: true, cancelable: true }));
  }
}
