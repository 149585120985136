interface Scripts {
    name: string;
    src: string;
}
export const ScriptStore: Scripts[] = [
    {name: 'es', src: 'https://cdn.agentbot.net/core/5dd112ebe06d075a2f154ea994c6507d.js'},
    {name: 'es_MX', src: 'https://cdn.agentbot.net/core/5dd112ebe06d075a2f154ea994c6507d.js'},
    {name: 'es_CO', src: 'https://cdn.agentbot.net/core/880d57133ef743e4ffed8405f499bb5e.js'},
    {name: 'es_ES', src: 'https://cdn.agentbot.net/core/7a51b05ce45b75f9dc85a1470f27bb41.js'},
    {name: 'es_US', src: 'https://cdn.agentbot.net/core/79872576c6c81a076369d4572e9e77ae.js'},
    {name: 'en', src: 'https://cdn.agentbot.net/core/d308859c1615895b44f290c77619ce4e.js'},
    {name: 'en_US', src: 'https://cdn.agentbot.net/core/d308859c1615895b44f290c77619ce4e.js'},
    {name: 'en_GB', src: 'https://cdn.agentbot.net/core/b45332114987e5a321e148bb6cae4f37.js'},
    {name: 'en_PH', src: 'https://cdn.agentbot.net/core/55a59c0136b67108ba2ab1c08fabc4c8.js'},
    {name: 'es_test', src: 'https://cdn.agentbot.net/core/09ec79095e0615aedc6dd82ce1c8fc6e.js'},
    {name: 'en_test', src: 'https://cdn.agentbot.net/core/5944930adabc5178e14a0898770903dc.js'},
    {name: 'es_MX_test', src: 'https://cdn.agentbot.net/core/5dd112ebe06d075a2f154ea994c6507d.js'},
    {name: 'es_CO_test', src: 'https://cdn.agentbot.net/core/880d57133ef743e4ffed8405f499bb5e.js'},
    {name: 'es_ES_test', src: 'https://cdn.agentbot.net/core/7a51b05ce45b75f9dc85a1470f27bb41.js'},
    {name: 'es_US_test', src: 'https://cdn.agentbot.net/core/79872576c6c81a076369d4572e9e77ae.js'},
    {name: 'en_US_test', src: 'https://cdn.agentbot.net/core/d308859c1615895b44f290c77619ce4e.js'},
    {name: 'en_GB_test', src: 'https://cdn.agentbot.net/core/b45332114987e5a321e148bb6cae4f37.js'},
    {name: 'en_PH_test', src: 'https://cdn.agentbot.net/core/55a59c0136b67108ba2ab1c08fabc4c8.js'},

    {name: 'es_ES_private', src: 'https://cdn.agentbot.net/core/25c0bc960fa7bdbc47a53731d575c453.js'},
    {name: 'es_ES_public', src: 'https://cdn.agentbot.net/core/b746a6b30395bf93705c9e0d9385bbf7.js'},
    {name: 'es_CO_private', src: 'https://cdn.agentbot.net/core/b5c577600b26ff54c7a3bf9bb93c79e2.js'},
    {name: 'es_CO_public', src: 'https://cdn.agentbot.net/core/29c5f3a5489daab83243e5d2df8fb37d.js'},
    {name: 'es_MX_private', src: 'https://cdn.agentbot.net/core/4e59f2e7da2e71a85d1de3856d8b3702.js'},
    {name: 'es_MX_public', src: 'https://cdn.agentbot.net/core/6078e80980deddaa7719b2199fc2ab97.js'},
    {name: 'en_US_private', src: 'https://cdn.agentbot.net/core/dada62aac9f5310481dc78a3f346d7c6.js'},
    {name: 'en_US_public', src: 'https://cdn.agentbot.net/core/1342817364ceea22808ce6597a88f7bc.js'},
    {name: 'en_PH_private', src: 'https://cdn.agentbot.net/core/02dd9a333e6c261ed2cc5e0c753010cb.js'},
    {name: 'en_PH_public', src: 'https://cdn.agentbot.net/core/e5facb4779e45b1dc56281d416becb3b.js'},
    {name: 'en_GB_private', src: 'https://cdn.agentbot.net/core/772cfe856456bed691faa3f428bc25ff.js'},
    {name: 'en_GB_public', src: 'https://cdn.agentbot.net/core/b4a788759f0fc0864e8d375f3d87ad14.js'},
    {name: 'es_GT_public', src: 'https://cdn.agentbot.net/core/fc04607bb8ea9d310e74121dd295b4a0.js'},
    {name: 'es_GT_private', src: 'https://cdn.agentbot.net/core/0a4bc8b40492fd6d27ad51dbe41a691c.js'},
    {name: 'es_PA_public', src: 'https://cdn.agentbot.net/core/3f07b959b21658b32b52fa208311125c.js'},
    {name: 'es_PA_private', src: 'https://cdn.agentbot.net/core/61ebf4e355cada6d254f697a7f1ecc41.js'},
    {name: 'es_PE_public', src: 'https://cdn.agentbot.net/core/e63cb89f387588bb05630491278904b4.js'},
    {name: 'es_PE_private', src: 'https://cdn.agentbot.net/core/c230c1c77e4270dda8cfefcf3d3989c1.js'},
    {name: 'es_DO_public', src: 'https://cdn.agentbot.net/core/77500151ee9e4051859930845222c1f8.js'},
    {name: 'es_DO_private', src: 'https://cdn.agentbot.net/core/62bb053473ecb899b64a6c75fe0e4963.js'},
    {name: 'es_PR_public', src: 'https://cdn.agentbot.net/core/2e896f412406c84defac106c19e48f28.js'},
    {name: 'es_PR_private', src: 'https://cdn.agentbot.net/core/a72ce757746417e3ef064158435d99f5.js'},
    {name: 'es_NI_public', src: 'https://cdn.agentbot.net/core/c1d2510babd5713287b0db0c31e71d92.js'},
    {name: 'es_NI_private', src: 'https://cdn.agentbot.net/core/80513bb30d31aaa153e3f2f80ae54dd5.js'},

    {name: 'es_ES_test_private', src: 'https://cdn.agentbot.net/core/25c0bc960fa7bdbc47a53731d575c453.js'},
    {name: 'es_ES_test_public', src: 'https://cdn.agentbot.net/core/b746a6b30395bf93705c9e0d9385bbf7.js'},
    {name: 'es_CO_test_private', src: 'https://cdn.agentbot.net/core/b5c577600b26ff54c7a3bf9bb93c79e2.js'},
    {name: 'es_CO_test_public', src: 'https://cdn.agentbot.net/core/29c5f3a5489daab83243e5d2df8fb37d.js'},
    {name: 'es_MX_test_private', src: 'https://cdn.agentbot.net/core/4e59f2e7da2e71a85d1de3856d8b3702.js'},
    {name: 'es_MX_test_public', src: 'https://cdn.agentbot.net/core/6078e80980deddaa7719b2199fc2ab97.js'},
    {name: 'en_US_test_private', src: 'https://cdn.agentbot.net/core/dada62aac9f5310481dc78a3f346d7c6.js'},
    {name: 'en_US_test_public', src: 'https://cdn.agentbot.net/core/1342817364ceea22808ce6597a88f7bc.js'},
    {name: 'en_PH_test_private', src: 'https://cdn.agentbot.net/core/02dd9a333e6c261ed2cc5e0c753010cb.js'},
    {name: 'en_PH_test_public', src: 'https://cdn.agentbot.net/core/e5facb4779e45b1dc56281d416becb3b.js'},
    {name: 'en_GB_test_private', src: 'https://cdn.agentbot.net/core/772cfe856456bed691faa3f428bc25ff.js'},
    {name: 'en_GB_test_public', src: 'https://cdn.agentbot.net/core/b4a788759f0fc0864e8d375f3d87ad14.js'},
    {name: 'es_GT_test_public', src: 'https://cdn.agentbot.net/core/fc04607bb8ea9d310e74121dd295b4a0.js'},
    {name: 'es_GT_test_private', src: 'https://cdn.agentbot.net/core/0a4bc8b40492fd6d27ad51dbe41a691c.js'},
    {name: 'es_PA_test_public', src: 'https://cdn.agentbot.net/core/3f07b959b21658b32b52fa208311125c.js'},
    {name: 'es_PA_test_private', src: 'https://cdn.agentbot.net/core/61ebf4e355cada6d254f697a7f1ecc41.js'},
    {name: 'es_PE_test_public', src: 'https://cdn.agentbot.net/core/e63cb89f387588bb05630491278904b4.js'},
    {name: 'es_PE_test_private', src: 'https://cdn.agentbot.net/core/c230c1c77e4270dda8cfefcf3d3989c1.js'},
    {name: 'es_DO_test_public', src: 'https://cdn.agentbot.net/core/77500151ee9e4051859930845222c1f8.js'},
    {name: 'es_DO_test_private', src: 'https://cdn.agentbot.net/core/62bb053473ecb899b64a6c75fe0e4963.js'},
    {name: 'es_PR_test_public', src: 'https://cdn.agentbot.net/core/2e896f412406c84defac106c19e48f28.js'},
    {name: 'es_PR_test_private', src: 'https://cdn.agentbot.net/core/a72ce757746417e3ef064158435d99f5.js'},
    {name: 'es_NI_test_public', src: 'https://cdn.agentbot.net/core/c1d2510babd5713287b0db0c31e71d92.js'},
    {name: 'es_NI_test_private', src: 'https://cdn.agentbot.net/core/80513bb30d31aaa153e3f2f80ae54dd5.js'}

  ];

export enum languagesAvailable {
    en,
    es,
    en_test,
    es_test,
    es_MX,
    es_CO,
    es_ES,
    es_US,
    en_US,
    en_PH,
    en_GB,
    es_MX_test,
    es_CO_test,
    es_ES_test,
    es_US_test,
    en_US_test,
    en_PH_test,
    en_GB_test,

    es_MX_private,
    es_CO_private,
    es_ES_private,
    en_US_private,
    en_PH_private,
    en_GB_private,
    es_GT_private,
    es_PA_private,
    es_PE_private,
    es_DO_private,
    es_PR_private,
    es_NI_private,

    es_MX_public,
    es_CO_public,
    es_ES_public,
    es_GT_public,
    es_PA_public,
    es_PE_public,
    es_DO_public,
    es_PR_public,
    es_NI_public,
    en_US_public,
    en_PH_public,
    en_GB_public,

    es_MX_test_public,
    es_CO_test_public,
    es_ES_test_public,
    en_US_test_public,
    en_PH_test_public,
    en_GB_test_public,
    es_GT_test_public,
    es_PA_test_public,
    es_PE_test_public,
    es_DO_test_public,
    es_PR_test_public,
    es_NI_test_public,


    es_MX_test_private,
    es_CO_test_private,
    es_ES_test_private,
    en_US_test_private,
    en_PH_test_private,
    en_GB_test_private,
    es_GT_test_private,
    es_PA_test_private,
    es_PE_test_private,
    es_DO_test_private,
    es_PR_test_private,
    es_NI_test_private
}
