import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/';
import { Logger } from '../angular-services-v7/logger.service';
import { Profile, CustomerDetail, LegalEntity } from '../models';
import { Application } from '../models/application';
import { User } from '../models/user';

class LegalEntityChange {
    changed: boolean;
    value: number;
}

@Injectable()
export class StorageService {

    public legalEntityChanged$ = new BehaviorSubject<LegalEntityChange>(null);

    constructor() { }

    public getFromLocalStorage(key: string): string {
        return localStorage.getItem(key);
    }

    public getFromSessionStorage(key: string): string {
        return sessionStorage.getItem(key);
    }

    public get selectedLegalEntity(): LegalEntity {
      const legalEntityInStorage = sessionStorage.getItem('user_legal_entity');
      if(legalEntityInStorage && legalEntityInStorage !== 'undefined' && legalEntityInStorage !== 'null') {
        try {
          return JSON.parse(legalEntityInStorage);
        } catch (error) {
          console.log('Error parsing legal entity from storage', error);
          return undefined;
        }
      }
      else {
        return undefined;
      }
    }

    public get userId(): number {
        const profile = sessionStorage.getItem('user_profile');
        const profileJson = profile ? JSON.parse(profile) as Profile : undefined;
        return profileJson ? profileJson.userId : undefined;
    }

    public get userApplications(): Application[] {
        const _userApps = sessionStorage.getItem('user_applications');
        return (_userApps) ? JSON.parse(_userApps) : [];
    }

    public get defaultCustomer(): CustomerDetail {
        const customer = sessionStorage.getItem('user_customer');
        let customerJson: CustomerDetail;
        try {
            customerJson = JSON.parse(customer);
        } catch (error) {
            Logger.log('User does not have default customer');
        }
        return customerJson;
    }

    public get userCountry(): string {
        const country = sessionStorage.getItem('country') || undefined;
        return country;
    }


    getUserListPermissions(): User[] {
        const usersStorage = sessionStorage.getItem('users_edit');
        const usersEdit = usersStorage ? JSON.parse(usersStorage) as User[] : [];
        return usersEdit;
    }

    setUserListPermissions(users: User[]) {
        sessionStorage.setItem('users_edit', JSON.stringify(users));
    }

    clearUserListPermissions() {
        sessionStorage.removeItem('users_edit');
    }
}
