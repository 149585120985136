import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { DCMConstants } from 'src/app/helpers/DCM.constants';
import { UserProcess, UserProcessCat } from 'src/app/models/userProcess';
import { UserProcessService } from 'src/app/services/user-process.service';

@Component({
  selector: 'customize-notifications-modal',
  templateUrl: './customize-notifications.modal.html',
  styleUrls: ['./customize-notifications.modal.scss']
})
export class CustomizeNotificationsModal implements OnInit {
  @Input() viewModal = false;
  @Input() userProcessCat: UserProcessCat[];
  @Input() userProcesses: UserProcess[];
  @Input() userId: number;
  @Output() eventModal: EventEmitter<any> = new EventEmitter<any>();

  public notificationSettingProcess = DCMConstants.USER_PROCESSES.FirstLoginNotificationSettings;
  public description = '';

  constructor(
    private router: Router,
    public translationService: TranslationService,
    private userProcessService: UserProcessService,
  ) { }

  ngOnInit(): void {
    this.description = this.translationService.pt('views.modal.notification-preferences.text1') + ' '
      + this.translationService.pt('views.modal.notification-preferences.text2');
  }

  public goToNotificationSettings(userId, processName) {
    this.viewModal = false;
    this.userProcessService.deleteUserProcess(userId, this.getUserProcessByName(processName)?.processId).subscribe();
    this.router.navigate(['/settings'], { queryParams: { activeTab: 'notifications' }});
  }

  public closeNotificationSettingsModal(userId, processName) {
    this.viewModal = false;
    this.userProcessService.deleteUserProcess(userId, this.getUserProcessByName(processName)?.processId).subscribe();
    this.eventModal.emit();
  }

  private getUserProcessByName(processName: string) {
    const processId = this.userProcessCat.find(p => p.processName === processName)?.processId;
    return this.userProcesses.find(up => up.processId === processId);
  }
}
