import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { LocalizationComponent } from './components/localization/localization.component';
import { LoaderService } from './services/loader.service';
import { SessionService } from 'src/app/angular-services-v7/session.service';
import { FeatureToggleService } from 'src/app/feature-toggle-service/v1/src/feature-toggle.service';
import { CountlyService } from 'src/app/helpers-v1/countly.service';
import { StorageService } from './services/';
import { combineLatest, Subject } from 'rxjs/';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppInsightsService } from './services/app-insights.service';
import { environment } from '../environments/environment';
import { AppFeatures } from '../toggleConfig';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends LocalizationComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  private destroyNotifier$: Subject<void> = new Subject();
  public cobrowseKey: string = environment.cobrowseKey;
  currentCustomer: any = 'Maria';
  public showCobrowse = false;
  private cookiebot = window['Cookiebot'];

  constructor(
    private router: Router,
    public translationService: TranslationService,
    private featureToggleService: FeatureToggleService,
    public loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private session: SessionService,
    private countly: CountlyService,
    private appInsights: AppInsightsService,
    private cdref: ChangeDetectorRef,
  ) {
    super();
    this.cmxTranslation = this.translationService;
    try {
      const $htmlElement = document.getElementsByTagName('html');
      if (this.cmxTranslation.selectedLanguage.rtl) {
        $htmlElement[0].dir = 'rtl';
      } else {
        $htmlElement[0].dir = 'ltr';
      }
    } catch ($exception) {
      console.error($exception);
    }

    //-------------------  For testing purposes  -------------------------
    // window.addEventListener("error", (event) => {
    //   const { message, filename, lineno, colno, error } = event;
    //   console.log('Captured uncaught error:', message, filename, lineno, colno, error);
    // });
  }

  ngOnInit() {
    this.appInsights.loadAppInsights();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    }); // window['dataLayer']
    if (window as { [key: string]: any }['dataLayer']) {
      this.sendDataToGoogleTagManager();
    }
    this.setCobrowseFeature();
  }

  ngOnDestroy(): void {
    this.destroyNotifier$.next();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  private sendDataToGoogleTagManager(): void {
    let currentUrl: string;
    const router$ = combineLatest(
      this.session.currentLegalEntity,
      this.router.events.pipe(
        //filter((routerEvent: NavigationEnd) => routerEvent instanceof NavigationEnd)
        filter(
          (routerEvent): routerEvent is NavigationEnd =>
            routerEvent instanceof NavigationEnd
        )
      )
    ).pipe(
      map(([legalEntity, routerEvent]) => {
        currentUrl = '';
        currentUrl = routerEvent.url;
        let childRoute = this.activatedRoute.firstChild;
        while (childRoute) {
          if (childRoute.firstChild) {
            childRoute = childRoute.firstChild;
          } else if (childRoute.snapshot) {
            return { route: childRoute.snapshot, legalEntity: legalEntity };
          } else {
            return undefined;
          }
        }
      }),
      filter((event) => event !== undefined),
      map((event) => {
        if (
          this.session.isLoggedIn &&
          !this.storageService.selectedLegalEntity
        ) {
          return;
        }
        if (event?.legalEntity || !this.session.isLoggedIn) {
          let application = 'Cross_Services';
          if (currentUrl.includes('user-management')) {
            application = 'User_Management';
          }
          const customerId = event?.legalEntity
            ? (event.legalEntity as any).legalEntityTypeCode
            : '';
          if (event?.route.data['track']) {
            this.countly.addGTM(
              application,
              event.route.data['routeName'],
              customerId
            );
          }
        }
      }),
      takeUntil(this.destroyNotifier$)
    );
    router$.subscribe();
  }

  private setCobrowseFeature() {
    const country = sessionStorage.getItem('country');
    if (country) {
      this.featureToggleService.isLoaded$.subscribe(async () => {
        this.showCobrowse = this.featureToggleService.isEnabledFor(
          AppFeatures.CoBrowse,
          { country }
        );
      });
    }
  }

}
